import { useContext } from "react"
import { PreviewStyle } from "../../../style"
import { PrecartContext } from "../../../../../providers/precartProvider"

export function PreviewImage({ image }) {
    const style = PreviewStyle()
    const precart = useContext(PrecartContext)

    const fullScreenImage = () => {
        if(window.innerWidth < 500) {
            precart.setShowFullImage(true)
        }
    }
    
    return (
        <div style={style.preview} onClick={fullScreenImage}>
            <img style={style.previewImage} width="auto" height="auto" alt='Fabric Preview' title='Fabric Preview' loading="eager" src={image} />
        </div>
    )
}