import { useContext } from "react";
import { THEMES } from "../../../../../data/themes.mjs";
import { defaultTheme } from "../../../../../config.mjs";
import { ReactComponent as CheckmarkIcon } from '../../../../../icons/checkmark-circle.svg'
import { PrecartContext } from "../../../../../providers/precartProvider";
import { FileproOptionRowsStyle } from "../../../style";

export function MatSelection() {
    const style = FileproOptionRowsStyle()
    const theme = THEMES[defaultTheme]
    const precart = useContext(PrecartContext)

    let rowOptions = []
    if(precart.fileproOptions && precart.fileproOptions.length > 0) {
        precart.fileproOptions.forEach((option) => {
            if(!rowOptions.includes('FRONT SET') && option.Type.toLowerCase().includes('front set')) 
                rowOptions.push('FRONT SET')
            else if(!rowOptions.includes('CARGO') && option.Type.toLowerCase().includes('cargo')) 
                rowOptions.push('CARGO')
            else if(!rowOptions.includes('FULL SET (2 ROWS)') && option.Type.toLowerCase().includes('full set')) 
                rowOptions.push('FULL SET (2 ROWS)')
        })
        rowOptions.sort()
        rowOptions.reverse()
    }

    const getImage = (type) => {
        switch(type) {
            case 'CARGO': return '/img/styles/1bucket.jpg'
            case 'FULL SET (2 ROWS)': return '/img/styles/1bench.jpg'
            default: return '/img/styles/1bucket.jpg'
        }
    }

    return (
        <div style={style.rowContainer}>
            { rowOptions.map((type, id) => {
                return <div key={id} style={{...style.rowBorder, backgroundColor: precart.currentFileproOption.includes(type) ? theme.specialBackground : "transparent"}} onClick={() => precart.setCurrentFileproOption(type)}>
                    <div style={{...style.row, borderColor: precart.currentFileproOption.includes(type) ? "transparent" : theme.pageText}}>
                        <CheckmarkIcon style={{...style.checkmark, opacity: precart.currentFileproOption.includes(type) ? "1" : "0"}} />
                        <img alt={type + ' Graphic'} title={type + ' Graphic'} loading="eager" src={getImage(type)} style={style.picture} height="60px" width="auto" />
                        <div style={style.text}>{type}</div>
                    </div>
                </div>
            })}
        </div>
    );
}