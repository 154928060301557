import { useRef, useState } from "react";
import { THEMES } from "../data/themes.mjs";
import { defaultTheme } from "../config.mjs";

export default function ImageZoom({ src, srcSmall }) {
    const theme = THEMES[defaultTheme]
    const sourceRef = useRef(null);
    const targetRef = useRef(null);
    const containerRef = useRef(null);

    const [opacity, setOpacity] = useState(0);
    const [offset, setOffset] = useState({ left: 0, top: 0 });

    const inner = {
        position: "relative",
        overflow: "hidden",
        display: "block",
        backgroundColor: theme.previewImageBackground
    }

    const target = {
        position: "absolute",
        left: `${offset.left}px`,
        top: `${offset.top}px`,
        opacity: opacity
    }

    const handleMouseEnter = () => {
        setOpacity(1);
    };

    const handleMouseLeave = () => {
        setOpacity(0);
    };

    const handleMouseMove = (e) => {
        const targetRect = targetRef.current.getBoundingClientRect(); // Image at original size
        const sourceRect = sourceRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();

        const xRatio = (targetRect.width - containerRect.width) / sourceRect.width;
        const yRatio = (targetRect.height - containerRect.height) / sourceRect.height;

        const left = Math.max(Math.min(e.pageX - sourceRect.left, sourceRect.width), 0);
        const top = Math.max(Math.min(e.pageY - sourceRect.top - window.scrollY, sourceRect.height), 0);

        setOffset({
            left: left * -xRatio,
            top: top * -yRatio
        });
    };

  return (
        <div style={inner}
            ref={containerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
        >
            <img 
                ref={sourceRef} 
                width="300px" 
                height="300px" 
                style={{opacity: 1 - opacity}} 
                alt="Source"
                title="Source"
                loading={window.innerWidth < 500 ? "lazy" : "eager"}
                src={srcSmall}
            />
            <img
                style={target}
                ref={targetRef}
                alt="Target"
                title="Target"
                opacity={opacity}
                offset={offset}
                src={src}
                loading="lazy"
                width="auto"
                height="auto"
            />
        </div>
  );
}
