import { THEMES } from "../../data/themes.mjs"
import { defaultTheme } from "../../config.mjs"
import { FontContext } from "../../providers/fontProvider"
import { useContext } from "react"

export function OuterStyle(){ 
    return {
        container: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "4vw",
            padding: "28px 0"
        }
    }
}

export function ContactStyle() { 
    const fonts = useContext(FontContext)
    const theme = THEMES[defaultTheme]
    
    return {
        container: {
            display: fonts.mobile ? "none" : "flex",
            flexDirection: "column",
            width: "280px",
            gap: "28px"
        },
        inner: {
            display: "flex",
            flexDirection: "column",
            gap: "10px"
        },
        title: {
            ...fonts.large,
            color: theme.pageHeader
        },
        line: {
            width: "100%",
            height: "3px",
            backgroundColor: theme.pageText
        },
        expContainer: {
            display: "flex",
            gap: "16px",
            padding: "4px 8px"
        },
        expHeader: {
            ...fonts.h4,
            fontWeight: "500",
            color: theme.pageHeader,
            transitionDuration: ".3s"
        },
        link: {
            textDecoration: "none",
            color: theme.pageText
        },
        expText: {
            ...fonts.small,
            color: theme.pageText,
            transitionDuration: ".3s"
        },
        icon: {
            height: "36px",
            width: "36px",
            minWidth: "36px",
            fill: theme.pageHeader
        }
    }
}

export function QuestionsStyle() { 
    const fonts = useContext(FontContext)
    const theme = THEMES[defaultTheme]
    
    return {
        contactContainer: {
            width: "100%",
            maxWidth: "850px",
            padding: "0 40px"
        },
        container: {
            width: "100%",
            padding: "12px 0"
        },
        individual: {
            display: "flex",
            flexDirection: "column",
            padding: "48px 24px",
        },
        title: {
            ...fonts.h1,
            color: theme.pageHeader,
            textTransform: "uppercase",
            fontWeight: "700"
        },
        categoryContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "24px",
            padding: "24px 0"
        },
        category: {
            ...fonts.h2,
            color: theme.pageHeader
        },
        question: {
            ...fonts.h3,
            color: theme.pageHeader
        },
        answer: {
            ...fonts.standard,
            color: theme.pageText
        }
    }
}

export function ContactFormStyle() { 
    const fonts = useContext(FontContext)
    const theme = THEMES[defaultTheme]
    
    return {
        form: {
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",
            flexFlow: "row wrap",
            width: "100%",
            maxWidth: "400px",
            height: "600px",
            backgroundColor: theme.componentBackground,
            padding: "20px",
            marginLeft: fonts.mobile ? null : "80px",
            color: theme.componentText
        },
        inputContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            width: "100%"
        },
        input: {
            fontSize: fonts.mobile ? "16px" : "14px",
            borderColor: theme.pageBackground
        },
        label: {
            ...fonts.standard,
            fontWeight: "500",
            lineHeight: "24px"
        },
        error: {
            ...fonts.small,
            color: theme.errorText,
            minHeight: "16px",
            fontWeight: "600"
        },
        messageBox: {
            ...fonts.small,
            width: "100%",
            border: `1px solid ${theme.pageBackground}`,
            borderRadius: "4px",
            height: "90px",
            outline: "none",
            padding: "10px 16px",
            resize: "none"
        },
        button: {
            ...fonts.h3,
            color: theme.buttonText,
            width: "100%",
            height: "60px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.buttonBackground,
            textDecoration: "none",
            cursor: "pointer",
            transitionDuration: ".3s"
        },
        sentMessage: {
            ...fonts.h3,
            alignSelf: "center",
            width: "100%",
            textAlign: "center"
        }
    }
}