import { useState, useEffect, useContext } from 'react';

import Paypal from '../components/paypal';
import { InputStyle } from '../style';
import { FontContext } from '../../../providers/fontProvider';
import { CheckoutSummary } from '../components/summary';

export function Payment({ page, submitOrder }) {
    const style = InputStyle()
    const fonts = useContext(FontContext)
    const [paypalClient, setPaypalClient] = useState()
    const [ccnumErr, setCcnumErr] = useState()
    const [expirationErr, setExpirationErr] = useState()
    const [cvvErr, setCvvErr] = useState()

    useEffect(() => {
        const api = process.env.REACT_APP_API_SERVER + '/web/order/retail/paypal/client'
        fetch(api, { headers: { authentication: process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) }})
            .then((response) => response.json())
            .then((data) => {
                setPaypalClient(data.payload)
            })
    }, [setPaypalClient])

    const ccnumMask = () => {
        const ccnum = document.getElementById('ccnum')
        const mask = "xxxx-xxxx-xxxx-xxxx"
        let current = ccnum.value
        current = current.replace(/\D+/g, '')
        
        let result = ''
        let counter = 0
        for(var i = 0; i < mask.length; i++) {
            if(counter >= current.length) break
            if(mask[i] === "x") {
                result += current[counter]
                counter++
            }
            else result += mask[i]
        }
        ccnum.value = result
    }

    const expirationMask = () => {
        const expiration = document.getElementById('expiration')
        const mask = "xx/xx"
        let current = expiration.value
        current = current.replace(/\D+/g, '')
        
        let result = ''
        let counter = 0
        for(var i = 0; i < mask.length; i++) {
            if(counter >= current.length) break
            if(mask[i] === "x") {
                result += current[counter]
                counter++
            }
            else result += mask[i]
        }
        expiration.value = result
    }

    const validatePayment = () => {
        let good = true
        if(document.getElementById('ccnum').value === '') { setCcnumErr('Required'); good = false }
        else {
            const cc = document.getElementById('ccnum').value.replace(/\D/g, "");
            if(cc.length !== 16) {
                setCcnumErr('Not a valid card number')
                good = false
            }
            else {
                var nCheck = 0, nDigit = 0, bEven = false;
                for (var n = cc.length - 1; n >= 0; n--) {
                    var cDigit = cc.charAt(n)
                    nDigit = parseInt(cDigit, 10);
                    if (bEven) {
                        if ((nDigit *= 2) > 9) nDigit -= 9;
                    }
                    nCheck += nDigit;
                    bEven = !bEven;
                }
                if((nCheck % 10) !== 0) {
                    setCcnumErr('Not a valid card number')
                    good = false
                }
            }
        }
        if(document.getElementById('expiration').value === '') { setExpirationErr('Required'); good = false }
        else {
            const expirationDate = document.getElementById('expiration').value
            const month = expirationDate.slice(0, 2)
            const year = expirationDate.slice(-2)
            let currentYear = new Date().getFullYear()
            currentYear = currentYear.toString().substring(2)
            
            if(expirationDate.length < 5 || month === 0 || month > 12 || year === 0 || year < currentYear) {
                setExpirationErr('Invalid')
                good = false
            }
        }
        if(document.getElementById('cvv').value === '') { setCvvErr('Required'); good = false }
        else {
            if(document.getElementById('cvv').value.length < 3) { setCvvErr('Invalid'); good = false }
            else if(document.getElementById('cvv').value.length > 4) { setCvvErr('Invalid'); good = false }
        }
        
        if(good) submitOrder({
            ccnum: document.getElementById('ccnum').value,
            expiration: document.getElementById('expiration').value,
            cvv: document.getElementById('cvv').value,
        })
    }
    
    return (
        <div style={style.container}>
            <div style={style.header}>{window.innerWidth < 700 ? 'Payment' : 'Payment Information'}</div>
            { page === 2 && fonts.mobile && <CheckoutSummary /> }
            { paypalClient ? <div style={{...style.form, justifyContent: 'flex-start'}} className={page === 2 ? '' : 'hidden-form'}>
                <div style={style.payByCreditContainer}>
                    <div style={style.payByCredit}>Pay By Credit</div>
                    <img src='/img/common/credit-cards.webp' width="137" height="20" alt="Credit Card Icons" />
                </div>
                <div style={{...style.inputOuter, flexWrap: "wrap"}}>
                    <div style={{...style.inputContainer, flex: window.innerWidth < 530 ? "1 0 100%" : "2"}}>
                        <div style={style.label}>Card Number</div>
                        <input style={style.input} id="ccnum" placeholder='' aria-label="Credit Card Number" className={ccnumErr ? 'err' : ''} type="text" onChange={ccnumMask} onFocus={() => setCcnumErr()} autoComplete="cc-number" />
                        <div style={style.error}>{ccnumErr}</div>
                    </div>
                    <div style={{...style.inputContainer, flex: window.innerWidth < 530 ? "1 0 45%" : "1"}}>
                        <div style={style.label}>Expiration</div>
                        <input style={style.input} id="expiration" placeholder='XX\XX' aria-label="Expiration" className={expirationErr ? 'err' : ''} type="text" onChange={expirationMask} onFocus={() => setExpirationErr()} autoComplete="cc-exp" />
                        <div style={style.error}>{expirationErr}</div>
                    </div>
                    <div style={{...style.inputContainer, flex: window.innerWidth < 530 ? "1 0 45%" : "1"}}>
                        <div style={style.label}>CVV</div>
                        <input style={style.input} id="cvv" placeholder='' aria-label="CVV" className={cvvErr ? 'err' : ''} type="text" onFocus={() => setCvvErr()} autoComplete="off" />
                        <div style={style.error}>{cvvErr}</div>
                    </div>
                </div>
                <div style={style.button} className='button-hover' onClick={validatePayment}>Pay Credit / Debit</div>
                <div style={style.orContainer}>
                    <div style={style.orLine} />
                    <div style={style.orText}>OR</div> 
                    <div style={style.orLine} />
                </div>
                { page === 2 && <Paypal client={paypalClient} submitOrder={submitOrder} /> }
            </div> : page === 2 ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : null }
        </div>
    );
}