import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ProductsProvider } from './providers/productsProvider';
import { FontProvider } from './providers/fontProvider';
import { CartProvider } from './providers/cartProvider';
import AppContainer from './Container';
import { useEffect, useState } from 'react';
import { clarityTags, googleMapKey, googleTags } from './config.mjs';

const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
})

function App() {
    const [tagsAdded, setTagsAdded] = useState(false)
    
    useEffect(() => {
        if(!tagsAdded) {
            if(process.env.REACT_APP_API_SERVER !== 'http://192.168.1.11') {
                for(var i = 0; i < googleTags.length; i++) {
                    window['dataLayer'] = window['dataLayer'] || []
                    window['dataLayer'].push({'gtm.start': new Date().getTime(), event:'gtm.js'})

                    var js = window.document.createElement('script')
                    js.async = true
                    js.src = 'https://www.googletagmanager.com/gtm.js?id=' + googleTags[i]
                    document.getElementsByTagName("head")[0].appendChild(js)
                }
                for(var v = 0; v < clarityTags.length; v++) {
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", clarityTags[v]);
                }
            }
            var mapJs = window.document.createElement('script')
            mapJs.async = true
            mapJs.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapKey}&libraries=places&loading=async`
            document.getElementsByTagName("head")[0].appendChild(mapJs)
            setTagsAdded(true)
        }
    }, [tagsAdded, setTagsAdded])

    return (
        <QueryClientProvider client={queryClient}>
            <ProductsProvider>
                <FontProvider>
                    <CartProvider>
                        <AppContainer />
                    </CartProvider>
                </FontProvider>
            </ProductsProvider>
        </QueryClientProvider>
    );
}

export default App;