import { StarRating } from "../../../../components/stars";
import { discountRate, website } from "../../../../config.mjs";
import { DescriptionStyle } from "../../style";

export function Description({ product }) {
    const style = DescriptionStyle()

    const scrollToReviews = () => {
        document.getElementById('customer-reviews').scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <div style={style.container}>
            <div style={style.product}>{website}</div>
            <h1 style={style.title}>{product.name}</h1>
            <div style={style.starContainer}>
                <StarRating rating={product.rating} specify={'0'} isSmall={window.innerWidth < 1080} />
                <div style={style.reviews} className="underline-hover" onClick={scrollToReviews}>{`(${product.reviews})`}</div>
            </div>
            <div style={style.price}>
                { `$${product.cost}` }
                { discountRate > 0 && <div style={style.oldPrice}>{`$${(product.cost * (1 + discountRate)).toFixed(2)}`}</div> }
            </div>
            { discountRate > 0 && <div style={style.discount}>
                {discountRate * 100}% Off. Price as Marked.
            </div> }
        </div>
    );
}