import { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import { ProductsContext } from "../../providers/productsProvider"
import { TOCStyle } from "../style"
import { announcement } from "../../config.mjs"
import Announcement from "./announcement"

export function TOC({faq, contact}) {
    const style = TOCStyle()
    const products = useContext(ProductsContext)
    const { lineID, productID } = useParams()

    const lineName = products.lines.filter((line) => line.siteID === lineID)[0]
    const productName = productID ? lineName.products.filter((product) => product.siteID === productID)[0] : null
        
    return(
        <>
            { announcement && announcement !== "" && <Announcement />}
            <div style={style.container}>
                { lineID || faq || contact ? 
                    <><Link style={style.link} to={'/products/'} className="page-hover">All Products</Link><div>&nbsp;&nbsp;/&nbsp;&nbsp;</div></>
                    : <div style={style.link}>All Products</div>
                }
                { lineID ?
                    productID ?
                        <><Link style={style.link} to={`/products/${lineID}/`} className="page-hover">{lineName.categoryName}</Link><div>&nbsp;&nbsp;/&nbsp;&nbsp;</div></>
                        : <div style={style.link}>{lineName.categoryName}</div>
                    : null
                }
                { productID && <div style={style.link}>{productName.name}</div> }
                { (faq || contact) && <div style={style.link}>{faq ? 'FAQ' : 'Contact'}</div> }
            </div>
        </>
    )
}