import { announcement } from "../../config.mjs";
import { AnnouncementStyle } from "../style";

export default function Announcement() {
    const style = AnnouncementStyle()
    
    return (
        <div style={style.container}>
            { announcement }
        </div>
    )
}