import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"
import { THEMES } from "../../data/themes.mjs";
import { defaultTheme } from "../../config.mjs";

export function CompletedStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            gap: "40px",
            width: "100%",
            maxWidth: "760px",
            padding: "4vw 40px"
        },
        pieces: {
            display: "flex",
            flexDirection: "column",
            gap: "18px"
        },
        title: {
            ...fonts.h2,
            padding: "12px 0 8px",
            color: theme.pageHeader
        },
        invoiceContainer: {
            border: `1px solid ${theme.pageText}`,
            backgroundColor: theme.componentBackground,
            width: "100%",
            padding: "10px 24px",
            textAlign: "center"
        },
        invoiceText: {
            ...fonts.standard,
            fontWeight: "600",
            color: theme.componentText
        },
        miniContainer: {
            display: "flex",
            flexDirection: fonts.mobile ? "column" : "row",
            gap: "16px"
        },
        header: {
            ...fonts.h3,
            marginTop: "12px",
            color: theme.pageHeader
        },
        infoContainer: {
            ...fonts.standard,
            color: theme.pageText,
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            flex: "1"
        },
        details: {
            ...fonts.standard,
            color: theme.pageText
        },
        costContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "8px"
        },
        costLine: {
            display: "flex",
            width: "200px"
        },
        cost: {
            ...fonts.h3,
            width: "50%",
            textAlign: "right",
            color: theme.pageHeader
        },
        costDetails: {
            ...fonts.h3,
            fontWeight: "400",
            width: "50%",
            textAlign: "right",
            color: theme.pageText
        },
        link: {
            textDecoration: "none",
            color: theme.buttonBackground,
            transitionDuration: ".3s"
        }
    }
}

export function CompletedItemsStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        container: {
            width: "100%",
            maxWidth: "700px",
            padding: "28px 24px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.backgroundColorA
        },
        itemContainer: {
            display: "flex",
            gap: fonts.mobile ? "8px" : "16px",
            padding: "24px 0"
        },
        image: {
            backgroundColor: "333333",
        },
        detailsContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: fonts.mobile ? "2px" : "6px"
        },
        name: {
            fontSize: fonts.mobile ? "14px" : "16px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            fontWeight: "600",
            color: theme.pageHeader
        },
        details: {
            fontSize: fonts.mobile ? "14px" : "16px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            fontWeight: "500",
            color: theme.pageText
        },
        costContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center"
        },
        cost: {
            fontSize: fonts.mobile ? "14px" : "16px",
            lineHeight: fonts.mobile ? "18px" : "20px",
            fontWeight: "600",
            display: "flex",
            gap: "8px",
            color: theme.pageHeader
        }
    }
}