import { ReviewsStyle } from "../../style";

export default function ShopperApproved() {
    const style = ReviewsStyle()
    let shopper_first
    
    function saLoadScript(src) { 
        var js = window.document.createElement('script'); 
        js.src = src; js.type = 'text/javascript'; 
        document.getElementsByTagName("head")[0].appendChild(js); 
    } 
    
    if (typeof(shopper_first) == 'undefined') saLoadScript('https://www.shopperapproved.com/widgets/36508/merchant/review-page/2c7tpk7dKNZF.js');
    shopper_first = true; 

    return (
        <>
            <div style={style.textContainer} id="customer-reviews">
                <div style={style.thin}>Customer</div>
                <div style={style.bold}>Reviews</div>
                { window.innerWidth > 500 && <div style={{...style.line, flex:" 1 0"}} /> }
            </div>
            <div id="SA_review_wrapper" ></div>
        </>
    )
}

// if (typeof(shopper_first) == 'undefined') saLoadScript('https://www.shopperapproved.com/widgets/testimonial/3.0/36508.js'); shopper_first = true; 

//<script type="text/javascript">var sa_interval = 5000;function saLoadScript(src) { var js = window.document.createElement('script'); js.src = src; js.type = 'text/javascript'; document.getElementsByTagName("head")[0].appendChild(js); } if (typeof(shopper_first) == 'undefined') saLoadScript('https://www.shopperapproved.com/widgets/36508/merchant/review-page/default.js'); shopper_first = true; </script>