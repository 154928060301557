import { useContext } from "react"
import { QuantityStyle } from "../../style"
import { PrecartContext } from "../../../../providers/precartProvider"

export default function Quantity() {
    const style = QuantityStyle()
    const precart = useContext(PrecartContext)

    const minus = () => {
        if(precart.quantity > 1) precart.setQuantity(precart.quantity - 1)
    }

    const plus = () => {
        precart.setQuantity(precart.quantity + 1)
    }
    
    return (
        <div style={style.container}>
            <div style={style.title}>Quantity</div>
            <div style={style.quantityContainer}>
                <button style={style.quantityButton} aria-label="Decrease" onClick={() => minus()}>&minus;</button>
                <div style={style.quantityInput}>{precart.quantity}</div>
                <button style={style.quantityButton} aria-label="Increase" onClick={() => plus()}>&#43;</button>
            </div>
        </div>
    )
}