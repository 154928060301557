import { website } from '../../../config.mjs';
import { HeaderStyle } from '../style';
import { useContext, useState } from 'react';
import { FontContext } from '../../../providers/fontProvider';
import { Link } from 'react-router-dom';
import { ReactComponent as ShoppingCartIcon } from '../../../icons/shopping-cart.svg'
import { CartContext } from '../../../providers/cartProvider';

export default function Header({ landing }) {
    const cart = useContext(CartContext)
    const [position, setPosition] = useState(0)
    const style = HeaderStyle(landing)
    const fonts = useContext(FontContext)

    window.addEventListener("scroll", () => {
        setPosition(window.scrollY)
    })

    function scrollTo(element) {
        document.querySelector(`#${element}`).scrollIntoView({ 
            behavior: 'smooth' 
        });
    }

    return (
        <div style={style.container} id='header'>
            <div style={style.inner}>
                
                <div style={style.logoContainer} onClick={() => window.scrollTo(0, 0)}>
                    {(position > 300 || landing.header.showLogoAtStart) && <img height={fonts.mobile ? "40px" : "60px"} width="auto" alt={`${website} Logo`} src={landing.header.logoImage} loading="eager" title={`${website} Logo`} />}
                </div>

                { !fonts.mobile ? <div style={style.linkContainer}>
                    <Link style={style.link} className={'h-text-' + landing.header.hoverColor} to='/products/'>
                        Our Products
                    </Link>
                    { landing.sections.map((section, id) => {
                        if(section.headerLink) 
                            return <div key={id} onClick={() => scrollTo(section.headerLinkID)} style={style.link} className={'h-text-' + landing.header.hoverColor}>
                                { section.headerLinkText }
                            </div>
                        else return null
                    })}
                    <Link style={style.link} className={'h-text-' + landing.header.hoverColor} to='./contact/'>
                        Contact Us
                    </Link>
                    <Link style={style.iconContainer} className={'h-text-' + landing.header.hoverColor} to={'./shopping-cart/'}>
                        <ShoppingCartIcon style={style.icon} fill='none' />
                        { cart.items && cart.items.length > 0 ? <div style={style.cartNumber}>
                            <div style={style.cartNumberText}>{cart.items.length}</div>
                        </div> : null }
                    </Link>
                </div> : 
                <Link style={style.iconContainer} className={'h-text-' + landing.header.hoverColor} to={'./shopping-cart/'}>
                    <ShoppingCartIcon style={style.icon} fill='none' />
                    { cart.items && cart.items.length > 0 ? <div style={style.cartNumber}>
                        <div style={style.cartNumberText}>{cart.items.length}</div>
                    </div> : null }
                </Link> }
            </div>
        </div>
    )
}