import { useContext } from "react"
import { ButtonStyle } from "../../style"
import { PrecartContext } from "../../../../providers/precartProvider"
import { useNavigate, useSearchParams } from "react-router-dom"

export default function CartButton({ product }) {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const style = ButtonStyle()
    const precart = useContext(PrecartContext)
    let ready = true

    if(product.colorOptions && product.colorOptions.length > 0) {
        if(precart.colorOption === undefined || precart.colorOption === null) ready = false
    }

    if(!product.disabledVehicleSelection) {
        if(!precart.vehicle.year || !precart.vehicle.make || !precart.vehicle.model) ready = false
        if(!precart.fileproOptions || precart.fileproOptions.length === 0) ready = false
    }

    if(product.fileproOption && product.fileproOption !== '') {
        if(!precart.currentFileproOption || precart.currentFileproOption.length === 0) ready = false
    }

    if(precart.fileproOptionsLoading) ready = false

    const addToCartClick = () => {
        if(precart.fileproOptions.length > 1)
            precart.setShowPrecheck()
        else {
            if(searchParams.has("e")) precart.addToCart([precart.fileproOptions[0]], false, parseInt(searchParams.get("e")))
            else precart.addToCart([precart.fileproOptions[0]])
            navigate('/shopping-cart/')
        }
    }

    return ( 
        ready ? 
        <button onClick={addToCartClick} style={{...style.container, ...style.good}} id={precart.fileproOptions.length <= 1 ? 'gtm-add-to-cart' : ''} className="button-hover">
            { searchParams.has("e") ? "Update Cart" : "Add to Cart" }
        </button>
        : <button disabled={true} style={{...style.container, ...style.bad}}>Select Options Above</button>
    )
}