export const THEMES = {
    "white-theme": {
        "headerBackground": "#ffffff",
        "headerText": "#171717",
        "headerTextHover": "#666666",

        "pageBackground": "#ffffff",
        "pageHeader": "#3e3e3e",
        "pageText": "#4f4f4f",
        "pageTextHover": "#999999",
        
        "componentBackground": "#eeeeee",
        "componentBackgroundHover": "#dddddd",
        "componentHeader": "#171717",
        "componentText": "#3e3e3e",

        "previewImageBackground": "#eeeeee", // Usually the same as component background
        
        "buttonBackground": "#008a00",
        "buttonBackgroundHover": "#129b12",
        "buttonText": "#f2f3f4",
        "inputFieldFocusedColor": "blue",

        "specialBackground": "#008a00",
        "specialText": "#f2f3f4",
        "errorText": "#be0000",

        "footerBackground": "#eeeeee",
        "footerText": "#282828",
        "footerTextHover": "#666666"
    },
    "test-theme": {
        "headerBackground": "#c2c2c2",
        "headerText": "#171717",
        "headerTextHover": "#666666",

        "pageBackground": "#c2c2c2",
        "pageHeader": "#ffffff",
        "pageText": "blue",
        "pageTextHover": "#999999",
        
        "componentBackground": "#666666",
        "componentBackgroundHover": "#dddddd",
        "componentHeader": "#ffffff",
        "componentText": "#3e3e3e",

        "previewImageBackground": "#eeeeee", // Usually the same as component background
        
        "buttonBackground": "#008a00",
        "buttonBackgroundHover": "#129b12",
        "buttonText": "#f2f3f4",
        "inputFieldFocusedColor": "blue",

        "specialBackground": "#008a00",
        "specialText": "#f2f3f4",
        "errorText": "#be0000",

        "footerBackground": "#eeeeee",
        "footerText": "#282828",
        "footerTextHover": "#666666"
    }
}