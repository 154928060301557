import { Header } from "../../components/header"
import { Footer } from "../../components/footer"
import { website, websiteURL } from "../../config.mjs"
import { Helmet } from "react-helmet-async"
import { ContainerStyle } from "./style"
import { useContext, useEffect, useState } from "react"
import { CartContext } from "../../providers/cartProvider"
import { useNavigate } from "react-router-dom"
import { CheckoutSummary } from "./components/summary"
import Processing from "./components/processing"
import Inputs from "./inputs"
import { FontContext } from "../../providers/fontProvider"


export default function CheckoutPage() {
    const style = ContainerStyle()
    const cart = useContext(CartContext)
    const fonts = useContext(FontContext)
    const navigate = useNavigate()
    const [sendingOrder, setSendingOrder] = useState(false)

    useEffect(() => {
        if(!cart || cart.items.length === 0) navigate('/shopping-cart', { replace: true })
    }, [cart, navigate])
    
    return(
        <>
            <Helmet>
                <title>Checkout - {website}</title>
                <meta name="description" content={website + ` Checkout Page. Order today by visiting us at ` + websiteURL + '!'} />
                <link rel="canonical" href={websiteURL + '/checkout'} />
            </Helmet>
            <Header />
            <div style={style.container}>
                <Inputs setSendingOrder={setSendingOrder} />
                { !fonts.mobile && <CheckoutSummary /> }
            </div>
            { sendingOrder && <Processing /> }
            <Footer />
        </>
    )
}