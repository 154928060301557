import React, { createContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { pageProducts, landingPage } from '../config.mjs';
import NetworkError from '../components/networkError';
import { LoadingPage } from '../components/loading';

export const ProductsContext = createContext({});

export function ProductsProvider({ children }) {    
    const apiAddress = process.env.REACT_APP_API_SERVER + '/web/site/products'

    const body = {
        method: "POST",
        headers: { "Content-Type": "application/json", "authentication": process.env.REACT_APP_DD_API + Date.now().toString().slice(5, 10) },
        body: JSON.stringify({products: pageProducts, landing: landingPage})
    }
    
    const getProducts = async() => {
        return await fetch(apiAddress, body)
        .then(response => response.json())
        .catch(err => err)
    }
    
    const {data, isLoading} = useQuery({
        queryKey: [pageProducts],
        queryFn: getProducts,
        throwOnError: true,
    })

    if(isLoading) return <LoadingPage />

    // console.log(data.payload)

    return <ProductsContext.Provider value={data.payload}>
        { data.status === 200 ? children : <NetworkError /> }
    </ProductsContext.Provider>;
}