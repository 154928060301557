import { useContext } from "react";
import { Carousel } from "./components/carousel";
import { PreviewImage } from "./components/preview";
import { PreviewStyle } from "../../style";
import { PrecartContext } from "../../../../providers/precartProvider";

export default function Preview({ product }) {
    const style = PreviewStyle()
    const precart = useContext(PrecartContext)

    function getImageSet() {
        let imageArray = []
        if(product.colorOptions && product.colorOptions.length > 0) {
            for(var i = 0; i < product.colorOptions[precart.colorOption].images.length; i++) 
                imageArray.push(product.colorOptions[precart.colorOption].images[i])
        }
        for(var j = 0; j < product.images.length; j++) imageArray.push(product.images[j])
        return imageArray
    }

    const imageSet = getImageSet()

    return (
        <div style={style.previewContainer} id="image-top">
            <Carousel imageSet={imageSet} />
            <PreviewImage image={imageSet[precart.imageSelection]} />
        </div>
    );
  }
  