import { DetailsStyle } from "../../style"

export default function Details(product) {
    const style = DetailsStyle()
    
    return(
        <div style={style.outer}>
            <div style={style.container}>
                <div style={style.piece}>
                    <div style={style.mini}>
                        <div style={style.header}>More Information</div>
                        <div style={style.text}>* ARMRESTS AND HEADREST INCLUDED *</div>
                        <div style={style.text}>{product.product.description1}</div>
                        <div style={style.text}>{product.product.description2}</div>
                    </div>
                </div>
                <div style={style.piece}>
                    <div style={style.mini}>
                        <div style={style.header}>Features</div>
                            <div style={style.liContainer}>
                                <ul>
                                { product.product.features && product.product.features.map((feat, id) => {
                                    return <li style={style.liText} key={id}>{feat}</li>
                                })}
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}