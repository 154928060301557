// STEPS FOR A NEW WEBSITE //
// Step 1: Edit this config File
// Step 3: Edit index.html to reflect the new site
// Step 4: Adjust .env to the correct Google API
// Don't forget to edit index.html to reflect site, adjust robots.txt, and change favicon

export const website = "Seat Décor"
export const websiteURL = "https://seatdecor.com"
export const websiteTitle = "Seat Décor - Premium Custom Seat Covers"
export const websiteDescription = "Shop for the best custom fitted car, truck, SUV, and Jeep seat covers at Seat Décor. Proudly made in the USA. 12 fabric options and over 30 colors available!"
export const defaultTheme = "white-theme"

export const defaultLogo = '/img/logos/seat-decor-logo-black.webp'
export const footerLogo = '/img/logos/seat-decor-logo-gray.webp'
export const favIcon = '/img/common/favicons/seat-decor.png'
export const copywriteLine = '© 2024 Seat Décor'

export const landingPage = 'seat-decor'
export const pageProducts = ['premium-seat-covers']
export const upgradedConsoleCost = 28.80
export const floormatFullSet2UpgradeCost = 25
export const floormatFullSet3UpgradeCost = 61
export const floormatCargoUpgradeCost = 100
export const discountRate = 0
export const announcement = "Get Additional 15% Off Your Order thru 10/24. Use Promo Code DECOR15 at Checkout."

export const contactPhone = '1-800-843-3274'
export const contactEmail = 'info@seatdecor.com'

export const googleTags = ['GTM-P2GQH7FC', 'GTM-WJVN57KH']
export const clarityTags = ['o900li7gtm']
export const googleMapKey = 'AIzaSyB8CsY2XHluKKi8kYK9LrgC4ZlnE1Qx0K0'